
const room_styles = [
    {
        label: "all", 
        value: "all"
    },
    {
        label: "basic", 
        value: "basic"
    },
    {
        label: "premium", 
        value: "premium"
    }
]
export default room_styles;

export const driver_styles = [
    {
        label: "all", 
        value: "all"
    },
    {
        label: "business", 
        value: "business"
    },
    {
        label: "comfort", 
        value: "comfort"
    }
]